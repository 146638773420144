import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item-detail" }
const _hoisted_2 = { class: "item-detail-content" }
const _hoisted_3 = { class: "item-name" }
const _hoisted_4 = { class: "item-amount" }
const _hoisted_5 = { class: "price-a-d" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "item-picture",
      style: _normalizeStyle(
        'background-image: url(' +
          _ctx.item.imageUrl +
          '), url(' +
          require(`@/assets/images/image-not-found-${_ctx.lang}.jpg`) +
          ')'
      )
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.name), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("TICKET_DETAIL.ITEMS_AMOUNT")) + " " + _toDisplayString(_ctx.item.pieces) + " " + _toDisplayString(_ctx.t("COMMON.PIECES")), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.price.value) + _toDisplayString(_ctx.t("CURRENCY.CURRENCY")), 1)
    ])
  ]))
}