
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { logoutUser } from "@/services/auth";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
      logoutUser
    };
  }
});
