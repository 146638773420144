
import { computed, defineComponent, onMounted, PropType } from "vue";
import { Invalid } from "@/types";

export default defineComponent({
  props: {
    insideText: {
      type: String,
      default: "",
      required: true
    },
    placeholderText: {
      type: String,
      required: true
    },
    invalid: {
      type: Object as PropType<Invalid>
    }
  },
  emits: ["setValid", "update:insideText"],
  setup(props) {
    const textAreaAdjust = (event: Event) => {
      const element = event.target as HTMLInputElement;
      element.style.height = "1px";
      element.style.height = 2 + element.scrollHeight + "px";
      element.parentElement!.style.height = element.scrollHeight + "px";
    };

    onMounted(() => {
      Array.from(
        window.document.getElementsByClassName("styled")
      ).forEach((element: any) =>
        element.addEventListener("input", textAreaAdjust)
      );
    });
    return {
      isInvalid: computed(() =>
        props.invalid ? props.invalid.invalid : undefined
      ),
      invalidText: computed(() =>
        props.invalid ? props.invalid.reason : undefined
      )
    };
  }
});
