
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { RmaCaseItem } from "@/types";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<RmaCaseItem>,
      required: true
    }
  },
  setup() {
    const { t, locale: lang } = useI18n();
    return { t, lang };
  }
});
