
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    optClass: {
      type: String
    },
    hidden: {
      type: Boolean
    }
  },
  setup() {
    return {};
  }
});
