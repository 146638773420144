import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "custom-card-header" }
const _hoisted_3 = { class: "step-number" }
const _hoisted_4 = { class: "step-title" }
const _hoisted_5 = {
  key: 1,
  class: "custom-card-header only-title"
}
const _hoisted_6 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-card", _ctx.optClass])
  }, [
    (_ctx.optClass === 'step')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _renderSlot(_ctx.$slots, "step-number")
            ]),
            _createElementVNode("span", _hoisted_4, [
              _renderSlot(_ctx.$slots, "step-title")
            ]),
            _renderSlot(_ctx.$slots, "optional")
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, [
            _renderSlot(_ctx.$slots, "header")
          ])
        ])),
    _createElementVNode("div", {
      class: _normalizeClass(["custom-card-content", { hidden: _ctx.hidden }])
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 2)
  ], 2))
}