import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d8463e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "textarea" }
const _hoisted_2 = ["value", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "label-invalid",
  id: "textarea-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("textarea", {
        class: _normalizeClass(["styled grey", { invalid: _ctx.isInvalid }]),
        name: "styled-textarea",
        value: _ctx.insideText,
        placeholder: _ctx.placeholderText,
        onInput: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.$emit('update:insideText', $event.target.value);
        _ctx.$emit('setValid');
      }),
        maxlength: "800",
        spellcheck: "false"
      }, "\n    ", 42, _hoisted_2)
    ]),
    (_ctx.isInvalid)
      ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.invalidText), 1))
      : _createCommentVNode("", true)
  ], 64))
}